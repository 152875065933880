@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Raleway';
    background-color: #f1f5f9;
}
div,p,Text{
    font-family: 'Roboto';
}
.footer-div:before {
    content: '';
    border-bottom: 60px solid #fff;
    border-left: 412px solid transparent;
    position: absolute;
    top: -60px;
    left: 0;
}
