::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #9a3412;
}

::-webkit-scrollbar-thumb:hover {
  background: #9a3412;
}

body{
  font-family: 'Roboto';
}
.slick-track {
  display: flex;
  flex-direction: row;
  gap: 10px;
}